import * as React from 'react';
import Pagination from '@mui/material/Pagination';

const Paginacion = ({ TotalPaguinas, PunteroPagina, HandleChange }) => {

    const handleChange = (event, value) => {
        HandleChange(value);
    };
    return (
        <>
            <Pagination count={TotalPaguinas} page={PunteroPagina} onChange={handleChange}  variant="outlined" shape="rounded" />
        </>
    );
}


export default Paginacion;

