import React from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Filter =({visibility,onChangefilter,datasfilter,name,namekey, title}) =>
{
    const [age, setAge] = React.useState('');
    const handleChange = (event) => {
        setAge(event.target.value);
        onChangefilter(event);
      };

    return(
        
        <FormControl hidden={visibility} sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-simple-select-label">{title}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Age"
                onChange={handleChange}
                value={age}
                name={name}
            >
                <MenuItem value="">
                    <em>All</em>
                </MenuItem>

                {
                    datasfilter.map((data) => (
                        <MenuItem key={data.id} value={data[namekey]}>{data[namekey]}</MenuItem>
                    ))
                }

            </Select>
        </FormControl>
    )
}

export default Filter;