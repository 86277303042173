import React from "react";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import Filter from "./filter.js";




const Search = ({ value, css_styles,OnClickBuscar, onChange, placeholder,Filters,DisabledBuuton}) =>
{
  const [visibility, setvisibility] = React.useState(true);

 

  return (
        <Paper
        component="form"
        sx={{ p: '0px 4px', display: 'flex', alignItems: 'center', width:'100%', marginBottom:'20px' }}
      >
        
        <InputBase
          sx={{ ml: 1, flex: 1  }}
          placeholder={placeholder}
          inputProps={{ 'aria-label': 'search google maps' }}
          value={value}
          onChange={onChange}
        />
        <IconButton disabled={DisabledBuuton} onClick={OnClickBuscar}  sx={{ p: '10px' }} aria-label="search" >
          <SearchIcon />
        </IconButton>
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        

      
      {
        Filters
        ?Filters.map((filter)=>(
          <Filter key={filter.id}  visibility={visibility} onChangefilter={filter.onChangefilter} datasfilter={filter.datasfilter} name={filter.name} namekey={filter.namekey} title={filter.title} />
        ))
        :<></>
      }
    
        <IconButton onClick={() => setvisibility(!visibility)} color="primary" sx={{ p: '10px' }} aria-label="directions">
          <TuneIcon />
        </IconButton>

      </Paper>
    )
}
export default Search