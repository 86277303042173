import * as React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
const Tables = ({ Datas, headers, result, OnClickSeleccionar, OnClickActualizar, OnClickEliminar,OnClickDocumento,search }) => {
    return (

        <TableContainer component={Paper} sx={{ marginBottom: '30px' }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {
                            headers.map((header, index) => header.visibility&& (
                                <TableCell  key={header.id}> <Typography fontWeight={700} variant='body2' align='center'>{header.name}</Typography> </TableCell>
                            ))
                        }
                        <TableCell> <Typography variant='body2' fontWeight={700}  align='center'>ACTION</Typography> </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Datas.filter(item => item[search].toLowerCase()
                        .indexOf(result.toLowerCase()) > -1).map((item) => (
                            <TableRow key={item.id}>
                                {
                                    headers.map((header) => header.visibility&& (
                                        <TableCell key={header.id} > <Typography fontWeight={400} variant='body2' align='center'>{item[header.field]} </Typography></TableCell>
                                    ))
                                }
                                <TableCell sx={{ alignItems:'center', padding: '5px' }}>
                                    

                                    <IconButton aria-label="editar" onClick={() => { OnClickSeleccionar(item.id_altar, item.nombre_finado);  }}>
                                        <CloudDownloadIcon sx={{ color: '#A5D089' }} />
                                    </IconButton>

                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
export default Tables


