import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom';


import routes from '../Routes/routes';
import Marquesina from '../Components/Marquesina';
import Box from '@mui/material/Box';


import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Box  className="App">
        <Marquesina Imagen={Marquesina}/>
        <Routes>

          {
            routes.map((rute, index) => (
              <Route key={index} path={rute.path} element={<rute.component />} />
            ))
          }
        </Routes>

      </Box>
    </BrowserRouter>
  );
}

export default App;
