import React, { Component } from "react";
import axios from 'axios';

// -----------Importar Componentes de Terceros------------------
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Form from 'react-bootstrap/Form';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import Search from "../../Components/Search";
import Tables from "../../Components/Table_Base";
import Paginacion from "../../Components/Paginacion";


import './Css/Styles.css';

const auth = {
  username: "memorialuser",
  password: "pNn5GvtpWw2x57o!sWEYp6"
};

const listheaders =
  [
    { id: 0, field: 'id_altar', name: 'ID', visibility: false },
    { id: 1, field: 'nombre_finado', name: 'FINADO', visibility: true },
    { id: 2, field: 'tipo', name: 'TIPO', visibility: true },
    { id: 3, field: 'nombre', name: 'NOMBRE', visibility: true },
    { id: 4, field: 'email', name: 'EMAIL', visibility: true },
    { id: 5, field: 'celular', name: 'CELULAR', visibility: true },
  ]

const ListValores = [5, 10, 20, 50, 100];

const Url = 'https://memorial.prophet.mx/api/altar/Buscar/';
const UrlAltarImagen = 'https://memorial.prophet.mx/api/altar/imagen/';


class PlanesFuturos extends Component {
  state = {
    ListMemorial: [],
    TotalPaguinas: 0,
    TotalRegistros: 0,
    PunteroPagina: 1,
    NumeroElemetos: 5,
    StringBusqueda: '',
    currentDate: new Date().toISOString().slice(0, 16).replace("T", " "),
    startDate: "2024-10-01 00:00",
    ImagenAltar: {
      Foto: '',
      Extencion: ''
    },
    result: '',
    Error: '',
  }


  handleTime = async (Time,Inicial) => {


    let url = '';

    if (Inicial) {
      url = `https://memorial.prophet.mx/api/altar/Buscar/${this.state.PunteroPagina}/${this.state.NumeroElemetos}/${encodeURIComponent(Time)}/${encodeURIComponent(this.state.currentDate)}`;

      if (this.state.StringBusqueda !== '')
      {
        url = `https://memorial.prophet.mx/api/altar/Buscar/${this.state.PunteroPagina}/${this.state.NumeroElemetos}/${this.state.StringBusqueda}/${encodeURIComponent(Time)}/${encodeURIComponent(this.state.currentDate)}`;

      }
      this.setState({startDate:Time})
    }
    else{
      url = `https://memorial.prophet.mx/api/altar/Buscar/${this.state.PunteroPagina}/${this.state.NumeroElemetos}/${encodeURIComponent(this.state.startDate)}/${encodeURIComponent(Time)}`;

      if (this.state.StringBusqueda !== '')
      {
        url = `https://memorial.prophet.mx/api/altar/Buscar/${this.state.PunteroPagina}/${this.state.NumeroElemetos}/${this.state.StringBusqueda}/${encodeURIComponent(this.state.startDate)}/${encodeURIComponent(Time)}`;
      }
      this.setState({currentDate:Time})
    }
    

    console.log(this.state.StringBusqueda);
    this.PeticionSeguimientoAltaresFechaGet(url);
  }

  handleDownload = async (idAltar, nombre_finado) => {

    const imagenaltar = await this.PeticionSeguimientoAltarImagenGet(idAltar);

    // Unir las partes de la imagen en base64
    const foto = imagenaltar.foto;
    console.log(foto);

    // Decodificar la parte base64
    const byteString = atob(foto);

    // Crear un ArrayBuffer y un Uint8Array para manejar los datos binarios
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    // Crear un Blob a partir de los datos binarios
    const blob = new Blob([uint8Array], { type: 'image/png' });

    // Crear un enlace de descarga
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = nombre_finado + ".png";  // Nombre del archivo a descargar

    // Simular un clic para iniciar la descarga
    link.click();
  };



  PeticionSeguimientoAltaresGet = async (PunteroPagina, NumeroElemetos, StringBusqueda) => {
    await axios.request(Url + PunteroPagina + '/' + NumeroElemetos + '/' + StringBusqueda, {
      auth: auth,
      headers: { "Content-Type": "application/json" },
    }
    ).then(response => {
      this.setState({ ListMemorial: response.data[1], TotalPaguinas: response.data[0].totalPaginas, TotalRegistros: response.data[0].totalRegistros });
    })
      .catch(error => {
        console.log(error);
      });
  }


  PeticionSeguimientoAltarImagenGet = async (idAltar) => {
    console.log(idAltar);
    try {
      const response = await axios.request(UrlAltarImagen + idAltar, {
        auth: auth,
        headers: { "Content-Type": "application/json" },
      });

      // Si la petición es exitosa, retorna response.data
      return response.data;

    } catch (error) {
      console.log(error);

      // Si ocurre un error, retorna una cadena vacía
      return '';
    }
  };




  onChange = async e => {
    // e.persist();

    if(e.target.value==='')
    {
      await this.setState({ result: e.target.value,StringBusqueda:'' });
    }
    else{
      await this.setState({ result: e.target.value });
    }
  }

  HandleChangePaguinacion = (PunteroPagina) => {
    this.setState({ PunteroPagina: PunteroPagina });

    let url = `https://memorial.prophet.mx/api/altar/Buscar/${PunteroPagina}/${this.state.NumeroElemetos}/${encodeURIComponent(this.state.startDate)}/${encodeURIComponent(this.state.currentDate)}`;

    if (this.state.StringBusqueda !== '') {
      url = `https://memorial.prophet.mx/api/altar/Buscar/${PunteroPagina}/${this.state.NumeroElemetos}/${this.state.StringBusqueda}/${encodeURIComponent(this.state.startDate)}/${encodeURIComponent(this.state.currentDate)}`;
    }

    console.log(url);
    this.PeticionSeguimientoAltaresFechaGet(url);
  }

  OnClickBuscar = () => {
    this.setState({ StringBusqueda: this.state.result })
    const url = `https://memorial.prophet.mx/api/altar/Buscar/${this.state.PunteroPagina}/${this.state.NumeroElemetos}/${this.state.result}/${encodeURIComponent(this.state.startDate)}/${encodeURIComponent(this.state.currentDate)}`;
    this.PeticionSeguimientoAltaresFechaGet(url);
  }


  PeticionSeguimientoAltaresFechaGet = async (UlrFecha) => {
    await axios.request(UlrFecha, {
      auth: auth,
      headers: { "Content-Type": "application/json" },
    }
    ).then(response => {
      this.setState({ ListMemorial: response.data[1], TotalPaguinas: response.data[0].totalPaginas, TotalRegistros: response.data[0].totalRegistros });
    })
      .catch(error => {
        console.log(error);
      });
  }

  handleSelectChange = (event) => {
    this.setState({ NumeroElemetos: event.target.value });
    let url = `https://memorial.prophet.mx/api/altar/Buscar/${this.state.PunteroPagina}/${event.target.value}/${encodeURIComponent(this.state.startDate)}/${encodeURIComponent(this.state.currentDate)}`;

    if (this.state.StringBusqueda !== '')
      {
        url = `https://memorial.prophet.mx/api/altar/Buscar/${this.state.PunteroPagina}/${event.target.value}/${this.state.StringBusqueda}/${encodeURIComponent(this.state.startDate)}/${encodeURIComponent(this.state.currentDate)}`;
      }
    this.PeticionSeguimientoAltaresFechaGet(url);
  }


  async componentDidMount() {
    const url = `https://memorial.prophet.mx/api/altar/Buscar/${this.state.PunteroPagina}/${this.state.NumeroElemetos}/${encodeURIComponent(this.state.startDate)}/${encodeURIComponent(this.state.currentDate)}`;
    this.PeticionSeguimientoAltaresFechaGet(url);

  }
  render() {
    console.log(this.state);
    return (
      <Box mt='50px' width='100%' sx={{ overflow: 'hidden' }} >
        <Box >
          <Container sx={{ background: '#ebebd3', borderRadius: '10px', maxWidth: { sm: 'xs !important', md: '800px !important', lg: '1100px !important', xl: '1400px !important' } }}>
            <Box sx={{ overflow: 'hidden' }}>
              <Typography mt={3} mb={0} color="#913b8e !important" className="Tipografia_base FZ_18 FW_7 .LH_1">Altares digitales</Typography>

              <Box mt='24px' sx={{ flexGrow: 1 }} >
                <Grid container spacing={2} >
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 8 }} >
                    <Search placeholder='Buscar finado' value={this.state.result} onChange={this.onChange} OnClickBuscar={this.OnClickBuscar} DisabledBuuton={this.state.result === '' ? true : false} />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3, xl: 2 }} sx={{ background: '#ebebd3' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Fecha inicial"
                        sx={{background:'white',width:'100%'}}
                        inputFormat="YYYY-MM-DD HH:mm"
                        onAccept={(newValue) => this.handleTime(dayjs(newValue).format('YYYY-MM-DD HH:mm'),true)}
                        renderInput={(params) => <TextField  id="outlined-search" type="datatime" />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3, xl: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Fecha final"
                        sx={{background:'white',width:'100%'}}
                        inputFormat="YYYY-MM-DD HH:mm"
                        onAccept={(newValue) => this.handleTime(dayjs(newValue).format('YYYY-MM-DD HH:mm'),false)}
                        renderInput={(params) => <TextField className="datatime" id="outlined-search" type="datatime" />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Box>
              <Box mt='48px'></Box>
              <Tables OnClickSeleccionar={this.handleDownload} Datas={this.state.ListMemorial.length > 0 ? this.state.ListMemorial : []} headers={listheaders} result='' search='nombre' />
              <Box mb='24px' width='100%' sx={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                <Box width='57px'>
                  <Form.Select className="valorelement" onChange={this.handleSelectChange} size="sm">
                    {
                      ListValores.map((item, index) => (
                        <option value={item}>{item}</option>
                      ))
                    }
                  </Form.Select>
                </Box>

                <Paginacion TotalPaguinas={this.state.TotalPaguinas} PunteroPagina={this.state.PunteroPagina} HandleChange={this.HandleChangePaguinacion} />
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    );
  }
}

export default PlanesFuturos;

